<template>
  <div class="login">
    <form class="form" @submit.prevent="submitForm">
      <h2 class="form__title">Bienvenido a Avigail</h2>
      <div class="form__input-wrapper">
        <label for="name" class="form__input-label">Nombre de usuario<br /></label>
        <el-input
            class=""
            v-model.trim="user.name"
            autofocus
            required
            placeholder="Tu nombre"
        />
      </div>
      <div class="form__input-wrapper">
        <label for="password" class="form__input-label">Contraseña<br /></label>
        <el-input
          class=""
          v-model.trim="user.password"
          type="password"
          show-password
          required
          placeholder="Tu contraseña"
        />
      </div>
      <el-button class="form__button" native-type="submit" type="primary"
        >Entrar</el-button
      >
    </form>
    <aside class="side-banner">
      <img
        src="https://images.pexels.com/photos/8386440/pexels-photo-8386440.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
        alt="banner-image"
        class="side-banner__image"
      />
    </aside>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      user: {
        name: '',
        password: '',
      },
    };
  },
  methods: {
    submitForm() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.form {
  align-self: center;
  justify-self: center;
  max-width: 18rem;
  width: 77%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  &__input-label {
    margin-left: 10px;
    color: $text-tertiary-color;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
}
.side-banner {
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media (max-width: 768px) {
  .login {
    grid-template-columns: 100%;
    min-width: 100vw;
  }
  .side-banner {
    display: none;
  }
}
</style>
